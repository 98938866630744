<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-content">
            <div class="heading-title">
              <h2>Fluxo de Caixa</h2>
              <p>
                Aqui você terá uma noção geral do seu fluxo financeiro.
              </p>
            </div>
          </div>
          <div class="mini-tab-root tab-elevation1 tab-rounded"  style="width:450px; float:right; margin-right:2px;">
            <div class="mini-tab-content">
                <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden;">
                  <div class="mini-tab-flex">
                      <button style="width: 33%;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b mini-tab-b-top" :class="miniTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTab(0, 0)">
                        <span>
                          <i class="fa fa-chart-line"></i>
                        </span>
                        <span class="MuiTouchRipple-root"></span>
                      </button>
                      <button style="width: 33%;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b mini-tab-b-top" :class="miniTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(1, tab1Size)">
                        <span>
                          <i class="fas fa-chart-bar"></i>
                        </span>
                        <span class="MuiTouchRipple-root"></span>
                      </button>
                      <button style="width: 33%;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b mini-tab-b-top" :class="miniTab.tab == 2 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTab(2, tab2Size)">
                        <span>
                          <i class="fas fa-chart-pie"></i>
                        </span>
                        <span class="MuiTouchRipple-root"></span>
                      </button>
                  </div>
                  <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${miniTab.indicator}% !important; background: #18b272 none repeat scroll 0% 0%;`"></span>
                </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="col-sm-9" style="padding-left: 0;">
        <div class="form-row hide-m">
          <div class="col-lg-3 col-md-3 col-sm-12 hide">
            <label>Filtro</label>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label>Filtro</label>
          </div>
        </div>
        <div v-for="(filter, n) in filters" class="form-row" :key="n">
          <div class="form-group col-lg-3 col-md-3 col-sm-5 p-right-none">
            <select-input
              v-model="filters[n].field"
              :reduce="(field) => field.value"
              :options="fields"
              :clearable="true"
            />
          </div>
          <div class="form-group col-lg-8 col-md-8 col-sm-7 p-right-none">
            <select-input
              v-if="filters[n].field == 'all'"
              multiple
              :reduce="(field) => field.value"
              label="label"
              v-model="filters[n].values"
              :options="filterAll"
              :clearable="true"
              @input="filterRequest"
            />
            <select-input
              v-else-if="filters[n].field == 'card_id'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="cards"
              :clearable="true"
            />
            <select-input
              v-else-if="filters[n].field == 'account_id'"
              multiple
              label="name"
              :reduce="(field) => field.id"
              v-model="filters[n].values"
              @input="filterRequest"
              :options="wallets"
              :clearable="true"
            />
            <input
              v-else
              type="text"
              class="form-control"
              placeholder="Selecione um campo"
              disabled
            />
          </div>
          <div class="form-group col-lg-1 hide">
            <button
              @click="removeFilter(n)"
              class="btn btn-danger btn-sm"
              style="height: 40px;"
            >
              <i class="fas fa-trash color-white"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-row hidden-xs">
          <div class="col-lg-12 col-md-12 col-sm-12 text-right" style="padding-right: 0px;">
            <label>Data</label>
          </div>
        </div>
        <div class="input-group mb-3" style="margin-top:5px;">
          <date-range-picker
            class="form-control mr-sm-2"
            value
            :options="optionsDate"
            @change="changeDate"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-right hide">
        <button @click="addFilter" type="button" class="btn btn-primary">
          <i class="fas fa-plus"></i> Adicionar Filtro
        </button>
      </div>
      <div class="col-sm-12 text-right">
        <hr>
      </div>
    </div>
    <div class="row" v-if="tabLine == 'line' || tabLine == 'bar'">
      <div class="col-md-12">
        <div class="card radius-10">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <v-chart class="chart-line" :option="tabLine == 'line' ? optionChart2 : optionChartBar" />
              </div>
              <div class="col-md-12 figure-stats">
                <div class="figure-section col-md-4" style="border-right: 1px solid #e6e6e6;">
                  <p>Receitas</p>
                  <h2 class="text-primary reports-income font-w-100">{{boxFlow.income.total | currency}}</h2>
                </div>
                <div class="figure-section col-md-4" style="border-right: 1px solid #e6e6e6;">
                  <p>Despesas</p>
                  <h2 class="text-danger reports-expenses font-w-100">{{boxFlow.expense.total | currency}}</h2>
                </div>
                <div class="figure-section col-md-4">
                  <p>Faturas</p>
                  <h2 class="reports-total font-w-100">{{report.transactions.report.total_card | currency}}</h2>
                </div>
              </div>
              <div class="col-md-12 total-balance">
                <p>Saldo total</p>
                <h2 class="reports-total">{{boxFlow.total.total | currency}}</h2>
              </div>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12" v-if="tabLine == 'bar' || tabLine == 'line'">
        <ul class="nav nav-tabs" id="myTab">
          <li class="nav-item">
            <a class="nav-link c-pointer" :class="{'active': tabActive == 'tab-all'}" @click="tabActive = 'tab-all'">Tudo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link c-pointer" :class="{'active': tabActive == 'tab-income'}" @click="tabActive = 'tab-income'">Receitas</a>
          </li>
          <li class="nav-item">
            <a class="nav-link c-pointer" :class="{'active': tabActive == 'tab-expenses'}" @click="tabActive = 'tab-expenses'">Despesas</a>
          </li>
          <li class="float-right">
            <button class="btn btn-primary btn-xs" style="display: none;" ng-click="CashFlow.showAdd()"><i class="fa fa-plus"></i> Adicionar valor</button>
          </li>
        </ul>
      </div>
      <div class="col-sm-12" v-if="tabLine == 'bar' || tabLine == 'line'">
        <div class="tab-content relative">
          <div class="cashflow-print hide"><i class="fa fa-print hide" @click="print"></i><i class="fa fa-file-pdf" v-tooltip.top="'Gerar PDF'" style="margin-left:10px;" @click="getPdf"></i></div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-all'}" v-if="tabActive == 'tab-all'">
            <div class="card card-tab table-responsive longer no-shadow" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="row hide" ng-class="CashFlow.add == true ? 'show' : 'hide' ">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="row">
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                        <label>Valor</label>
                        <input class="form-control currency ng-pristine ng-untouched ng-valid ng-empty" data-thousands="." data-decimal="," placeholder="0,00" name="value" ng-model="CashFlow.event.value">
                      </div>
                    
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                        <label>Tipo</label>
                        <select class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="type" ng-model="CashFlow.event.type_event"><option value="? undefined:undefined ?" selected="selected"></option>
                          <option value="income">Entrada</option>
                          <option value="expense">Saída</option>
                        </select>
                      </div>
                      <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4">
                        <label>Categoria</label>
                        <input class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="description" placeholder="Categoria" ng-model="CashFlow.event.description">
                      </div>
                      <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                        <label>Data</label>
                        <input type="text" class="form-control datepicker-current ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" required="" past="true" placeholder="Data" name="due_date" ng-model="CashFlow.event.date">
                      </div>
                      <div class="col-sm-2 col-sm-4 col-lg-2 col-xs-2 text-right">
                        <br>
                        <button class="btn btn-primary btn-sm" style="width: 30%;padding-left: 20px !important;" ng-click="CashFlow.renderVar('')"><i class="fa fa-check"></i></button>
                        <button class="btn btn-danger btn-sm" style="width: 30%;padding-left: 20px !important;" ng-click="CashFlow.hideAdd()"><i class="fa fa-times"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-bottom-0">
                <div class="m-bottom-0">
                    <section class="text-center">
                      <h3 class="text-left font-w-100">Saldo total <br><strong class="total-amount">{{boxFlow.total.total | currency}}</strong></h3>
                    </section>
                </div>
              </div>
              <hr>
              <h3 class="display-4">Receitas e Despesas</h3>
              <div class="row hidden-xs">
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4">
                  Valor
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                  Tipo
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Categoria
                </div>
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4 text-right">
                  Data
                </div>
              </div>
              <hr class="hidden-xs">
              <div class="row ng-scope" v-if="boxFlow.group.all.item.length == 0">
                <img :src="`assets/images/report.svg`" class="nothing-image w-15p" style="width: 10% !important">
                <h3 class="nothing-text">Nenhuma transação encontrada</h3>
              </div>
              <ul class="list-group" v-if="boxFlow.group.all">
                <li class="list-group-item ng-scope" v-for="(item, i) in boxFlow.group.all.item" :key="i">
                  <div class="row">
                    <div class="col-sm-4 col-lg-4 col-xs-4 col-xs-6">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-2 col-xs-6">
                      <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                      <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-6">
                      <span class="chart-legend-item relative c-pointer" style="border:none; margin:0px; padding:0px;" @click="categoryDetail('category-detail-modal', item.category == null ? {} :item.category.parent ? item.category.parent : item.category, item.amount, item.transaction_type)">
                        <div v-tooltip.top="`${item.category == null ? 'Sem categoria': item.category.parent ? item.category.parent.name : item.category.name}`" class="icon-category-overview" :style="`margin-right:0px;background: ${item.category == null ? '#ccc': item.category.parent ? item.category.parent.color : item.category.color}`">
                          <img :src="`assets/images/icons/png/${item.category == null ? 'cash': item.category.parent ? item.category.parent.icon : item.category.icon}.png`" />
                        </div>
                        &nbsp;<span class="hidden-sm visible-only-xs">{{item.category == null ? 'Sem categoria':item.category.parent ? item.category.parent.name : item.category.name}}</span>
                      </span>
                    </div>
                    <div class="col-sm-4 col-lg-4 col-xs-12 text-right">
                      <span class="ng-binding" v-if="boxFlow.group.all.is_month">{{ item.transaction_date | moment("DD/MM/YYYY") }}</span>
                      <span class="ng-binding" v-else>{{ item.transaction_date | moment("MMMM/YYYY") }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h4 class="display-4 text-right">Receitas: <span class="reports-income">{{boxFlow.income.total | currency}}</span></h4>
              <h4 class="display-4 text-right ">Despesas: <span class="reports-expenses">{{boxFlow.expense.total | currency}}</span></h4>
              <hr>
              <h3 class="display-4">Total: <span class="reports-total">{{boxFlow.total.total | currency}}</span></h3>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
            </div>
          </div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-income'}" v-if="tabActive == 'tab-income'">
            <div class="card card-tab table-responsive longer no-shadow" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="m-bottom-0">
                <div class="m-bottom-0">
                    <section class="text-center">
                      <h3 class="text-left font-w-100">Saldo total <br><strong class="total-amount">{{boxFlow.total.total | currency}}</strong></h3>
                    </section>
                </div>
              </div>
              <hr>
              <h3 class="display-4">Receitas</h3>
              <div class="row hidden-xs">
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4">
                  Valor
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                  Tipo
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Categoria
                </div>
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4 text-right">
                  Data
                </div>
              </div>
              <hr class="hidden-xs">
              <div class="row ng-scope" v-if="boxFlow.group.income.item.length == 0">
                <img :src="`assets/images/report.svg`" class="nothing-image w-15p">
                <h3 class="nothing-text">Nenhuma transação encontrada</h3>
              </div>
              <ul class="list-group" v-if="boxFlow.group.income">
                <li class="list-group-item ng-scope" v-for="(item, i) in boxFlow.group.income.item" :key="i">
                  <div class="row">
                    <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                      <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                      <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-6">
                      <span @click="categoryDetail('category-detail-modal', item.category == null ? {}: item.category.parent ? item.category.parent : item.category, item.amount, item.transaction_type)" class="chart-legend-item relative c-pointer" style="border:none; margin:0px; padding:0px;">
                        <div v-tooltip.top="`${item.category == null ? 'Sem categoria':item.category.parent ? item.category.parent.name : item.category.name}`" :style="`margin-right:0px;background: ${item.category.parent ? item.category.parent.color : item.category.color}`" class="icon-category-overview">
                          <img :src="`assets/images/icons/png/${item.category == null ? 'cash':item.category.parent ? item.category.parent.icon : item.category.icon}.png`" />
                        </div>
                        &nbsp;<span class="hidden-sm visible-only-xs">{{item.category == null ? 'Sem categoria': item.category.parent ? item.category.parent.name : item.category.name}}</span>
                      </span>
                    </div>
                    <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-12 text-right">
                      <span class="ng-binding" v-if="boxFlow.group.all.is_month">{{ item.transaction_date | moment("DD/MM/YYYY") }}</span>
                      <span class="ng-binding" v-else>{{ item.transaction_date | moment("MMMM/YYYY") }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h3 class="display-4">Total: + <span class="reports-income">{{boxFlow.income.total | currency}}</span></h3>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
            </div>
          </div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-expenses'}" v-if="tabActive == 'tab-expenses'">
            <div class="card card-tab table-responsive longer no-shadow" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="m-bottom-0">
                <div class="m-bottom-0">
                  <section class="text-center">
                    <h3 class="text-left font-w-100">Saldo total <br><strong class="total-amount">{{boxFlow.total.total | currency}}</strong></h3>
                  </section>
                </div>
              </div>
              <hr>
              <h3 class="display-4">Despesas</h3>
              <div class="row hidden-xs">
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4">
                  Valor
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                  Tipo
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Categoria
                </div>
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4 text-right">
                  Data
                </div>
              </div>
              <hr class="hidden-xs">
              <div class="row ng-scope" v-if="boxFlow.group.expenses.item.length == 0">
                <img :src="`assets/images/report.svg`" class="nothing-image w-15p">
                <h3 class="nothing-text">Nenhuma transação encontrada</h3>
              </div>
              <ul class="list-group" v-if="boxFlow.group.expenses">
                <li class="list-group-item ng-scope" v-for="(item, i) in boxFlow.group.expenses.item" :key="i">
                  <div class="row">
                    <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                      <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                      <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-6">
                      <span class="chart-legend-item relative c-pointer" style="border:none; margin:0px; padding:0px;" @click="categoryDetail('category-detail-modal', item.category == null ? {}:item.category.parent ? item.category.parent : item.category, item.amount, item.transaction_type)" >
                        <div v-tooltip.top="`${item.category == null ? 'Sem categoria': item.category.parent ? item.category.parent.name : item.category.name}`" class="icon-category-overview" :style="`margin-right:0px;background: ${item.category.parent ? item.category.parent.color : item.category.color}`">
                          <img :src="`assets/images/icons/png/${item.category == null ? 'cash': item.category.parent ? item.category.parent.icon : item.category.icon}.png`" />
                        </div>
                        &nbsp;<span class="hidden-sm visible-only-xs">{{item.category == null ? 'Sem categoria' :item.category.parent ? item.category.parent.name : item.category.name}}</span>
                      </span>
                    </div>
                    <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4 text-right">
                      <span class="ng-binding" v-if="boxFlow.group.expenses.is_month">{{ item.transaction_date | moment("DD/MM/YYYY") }}</span>
                      <span class="ng-binding" v-else>{{ item.transaction_date | moment("MMMM/YYYY") }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h3 class="display-4">Total: - <span class="reports-expenses">{{boxFlow.expense.total | currency}}</span> </h3>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12" v-if="tabLine == 'pier'">
        <div class="tab-content relative">
          <div class="cashflow-print"><i class="fa fa-print hide" @click="print"></i></div>
          <div class="tab-pane printable show">
            <div class="card table-responsive longer" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="m-bottom-0">
                <div class="m-bottom-0" style="float: left;">
                  <section class="text-center">
                    <h3 class="text-left font-w-100">Total <br><strong class="total-amount">{{report.transactions.report.total_category | currency}}</strong></h3>
                  </section>
                </div>
                <div class="mini-tab-root tab-elevation1 tab-rounded" style="width:310px !important; float: right; margin-top: 30px;">
                  <div class="mini-tab-content" style="min-height: 28px;">
                      <div class="mini-tab-scroller mini-tab-fixed" style="overflow: hidden; height: 35px;">
                        <div class="mini-tab-flex">
                            <button style="width: 150px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="pieTab.tab == 0 ? 'mini-tab-selected' : ''" tabindex="0" type="button" role="tab" aria-selected="true" @click="changeTabPie(0, 0)">
                              <span style="margin-top: -10px;">
                                Despesas
                              </span>
                              <span class="MuiTouchRipple-root"></span>
                            </button>
                            <button style="width: 150px;" class="mini-tab-button mini-tab-button-root mini-tab-button-root-color mini-tab-b" :class="pieTab.tab == 1 ? 'mini-tab-selected' : ''" tabindex="-1" type="button" role="tab" aria-selected="false" @click="changeTabPie(1, 150)">
                              <span style="margin-top: -10px;">
                                Receitas
                              </span>
                              <span class="MuiTouchRipple-root"></span>
                            </button>
                        </div>
                        <span class="mini-tab-indicator" :style="`width: 160px; height: 100%; z-index: 1; border-radius: 25px; left: ${pieTab.indicator}px; background: ${tabColor};`"></span>
                      </div>
                  </div>
                </div>
                <div style="clear: both;"></div>
              </div>
              <hr>
              <div class="row">
                <div class="col-lg-12">
                  <div class="row ng-scope" v-if="this.report.transactions.report.categories.length == 0">
                    <img :src="`assets/images/transaction.png`" class="nothing-image" style="width:10%; !important">
                    <h3 class="nothing-text">Nenhum lançamento</h3>
                  </div>
                </div>
                <div class="col-lg-7">
                  <div v-if="this.report.transactions.report.categories.length > 0">
                    <v-chart class="chart" :option="optionCat" />
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="chart-legend chart-legend--vertical" v-for="category in this.report.transactions.report.categories" :key="category.id">
                    <span class="chart-legend-item relative c-pointer" @click="categoryDetail('category-detail-modal', category.category.parent ? category.category.parent : category.category, category.total, tabActive == 'tab-income' ? 'income' : 'expense')">
                      <div class="icon-category-overview" :style="`background: ${category.category == null ? '#ccc' : category.category.parent ? category.category.parent.color : category.color}`">
                        <img :src="`assets/images/icons/png/${ category.category == null ? 'cash' : category.category.parent ? category.category.parent.icon : category.icon}.png`" />
                      </div>
                      {{category.category == null ? 'Sem categoria': category.category.parent ? category.category.parent.name : category.name}}
                      <strong style="position: absolute; right: 10px"
                        >{{category.total | currency}}</strong
                      >
                    </span>
                  </div>
                </div>
              </div>
              <hr>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
            </div>
          </div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-income'}" v-if="tabActive == 'tab-income'">
            <div class="card table-responsive longer" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="m-bottom-0">
                <div class="m-bottom-0">
                  <section class="text-center">
                    <h3 class="text-left font-w-100">Saldo total <br><strong class="total-amount">{{boxFlow.total.total | currency}}</strong></h3>
                  </section>
                </div>
              </div>
              <hr>
              <h3 class="display-4">Receitas</h3>
              <div class="row">
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4">
                  Valor
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                  Tipo
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Categoria
                </div>
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4 text-right">
                  Data
                </div>
              </div>
              <hr>
              <div class="row ng-scope" v-if="boxFlow.group.income.item.length == 0">
                <img :src="`assets/images/report.svg`" class="nothing-image w-15p">
                <h3 class="nothing-text">Nenhuma transação encontrada</h3>
              </div>
              <ul class="list-group" v-if="boxFlow.group.income">
                <li class="list-group-item ng-scope" v-for="(item, i) in boxFlow.group.income.item" :key="i">
                  <div class="row">
                    <div class="col-sm-4 col-lg-4 col-xs-4 col-xs-6">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-2 col-xs-6">
                      <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                      <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-2">
                      <strong class="ng-binding">{{item.category ? item.category.name : 'Sem categoria' }}</strong>
                    </div>
                    <div class="col-sm-4 col-lg-4 col-xs-4 col-xs-4 text-right">
                      <span class="ng-binding" v-if="boxFlow.group.all.is_month">{{ item.transaction_date | moment("DD/MM/YYYY") }}</span>
                      <span class="ng-binding" v-else>{{ item.transaction_date | moment("MMMM/YYYY") }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h3 class="display-4">Total: + <span class="reports-income">{{boxFlow.income.total | currency}}</span></h3>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
            </div>
          </div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-expenses'}" v-if="tabActive == 'tab-expenses'">
            <div class="card table-responsive longer" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="m-bottom-0">
                <div class="m-bottom-0">
                  <section class="text-center">
                    <h3 class="text-left font-w-100">Saldo total <br><strong class="total-amount">{{boxFlow.total.total | currency}}</strong></h3>
                  </section>
                </div>
              </div>
              <hr>
              <h3 class="display-4">Despesas</h3>
              <div class="row">
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4">
                  Valor
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                  Tipo
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Categoria
                </div>
                <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4 text-right">
                  Data
                </div>
              </div>
              <hr>
              <div class="row ng-scope" v-if="boxFlow.group.expenses.item.length == 0">
                <img :src="`assets/images/report.svg`" class="nothing-image w-15p">
                <h3 class="nothing-text">Nenhuma transação encontrada</h3>
              </div>
              <ul class="list-group" v-if="boxFlow.group.expenses">
                <li class="list-group-item ng-scope" v-for="(item, i) in boxFlow.group.expenses.item" :key="i">
                  <div class="row">
                    <div class="col-sm-4 col-lg-4 col-xs-4 col-xs-6">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-2 col-xs-6">
                      <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                      <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-6">
                      <strong class="ng-binding">{{item.category ? item.category.name : 'Sem categoria' }}</strong>
                    </div>
                    <div class="col-sm-4 col-lg-4 col-xs-4 col-xs-4 text-right">
                      <span class="ng-binding" v-if="boxFlow.group.expenses.is_month">{{ item.transaction_date | moment("DD/MM/YYYY") }}</span>
                      <span class="ng-binding" v-else>{{ item.transaction_date | moment("MMMM/YYYY") }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h3 class="display-4">Total: - <span class="reports-expenses">{{boxFlow.expense.total | currency}}</span> </h3>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { graphic } from 'echarts';
import AppService from '@/services/app.service';
import moment from 'moment-timezone';
import SelectInput from '@/components/SelectInput.vue';
import pace from 'pace-js';
import { useWindowSize } from 'vue-window-size';
import { moneyFormat } from '@/plugins/functions';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
  BarChart,
]);

export default {
  name: 'Home',
  components: {
    VChart,
    SelectInput,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      pieTab: {
        tab: 0,
        indicator: 0,
      },
      tab1Size: '33',
      tab2Size: '64.3',
      tabLine: 'line',
      tabIncome: 'expense',
      tabColor: '#ff1a1a',
      cards: [],
      wallets: [],
      filters: [
        {
          field: 'all',
          values: [
            'account',
          ],
        },
      ],
      fields: [
        { label: 'Tipo de despesa', value: 'all' },
        { label: 'Cartões', value: 'card_id' },
        { label: 'Contas', value: 'account_id' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      optionCat: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template = '';
            var balance = '';
            if(params[1]) {
              balance = moneyFormat((params[1].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }
            if(params[0]) {
              balance = moneyFormat((params[0].data));
              template = `${params[0].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }

            if(params[1] && params[0]) {
              balance = moneyFormat((params[1].data) - Math.abs(params[0].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1> <hr style="margin-top:5px;margin-bottom:5px;"> <p style="margin:0px; color: #cccccc !important;">Entradas: <span style="text-align: right; display:block; float: right;"> R$ ${moneyFormat(params[1].data)}</span></p><p style="color: #cccccc !important;">Saídas: <span style="text-align: right; display:block; float: right;">R$ ${moneyFormat(params[0].data)}</span></p>`;
            }
            return template;
          },
        },
        toolbox: {
          show: false,
        },
        color: ["#f62d51", "#dddddd", "#ffbc34"],
        calculable: true,
        series: [
          {
            type: 'pie',
            radius: [70, 100],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 5
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: [],
          },
        ],
      },
      tabActive: 'tab-all',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      monthStart: 0,
      from: '',
      to: '',
      report: {
        transactions: {report: {categories: []}}
      },
      boxFlow: {
        income: {},
        expense: {},
        total: {},
        group: {
          all: {
            item: [],
          },
          income: {
            item: [],
          },
          expenses: {
            item: [],
          }
        }
      },
      isLoading: true,
      isLoadingWallet: true,
      isLoadingCards: true,
      optionChart2: {
        legend: {
          data: ['Despesas', 'Receitas'],
          align: 'left'
        },
        tooltip: {
          trigger: "axis",
          show: true,
          backgroundColor : '#fff',
          axisPointer: {
            snap: true,
            type: "cross",
          },
          formatter: function(params){
            var template = '';
            var balance = '';
            if(params[1]) {
              balance = moneyFormat((params[1].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }
            if(params[0]) {
              balance = moneyFormat((params[0].data));
              template = `${params[0].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }

            if(params[1] && params[0]) {
              balance = moneyFormat((params[1].data) - Math.abs(params[0].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1> <hr style="margin-top:5px;margin-bottom:5px;"> <p style="margin:0px; color: #cccccc !important;">Entradas: <span style="text-align: right; display:block; float: right;"> R$ ${moneyFormat(params[1].data)}</span></p><p style="color: #cccccc !important;">Saídas: <span style="text-align: right; display:block; float: right;">R$ ${moneyFormat(params[0].data)}</span></p>`;
            }
            return template;
          }
        },
        xAxis: {
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 0,
          },
          data: [],
          silent: false,
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type : 'value',
          name: '',
          axisLabel: {
            formatter: function (value, index) {
              console.log(index);
              var text = "R$ "+value;
              return text;
            }
          }
        },
        series: [{
            name: 'Despesas',
            type: 'line',
            smooth: false,
            stack: 'transactions',
            areaStyle:{
              normal:{
                color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                    offset: 0,
                    color:'rgba(255,26,26,0.20)'
                }, {
                    offset: .34,
                    color:'rgba(255,26,26,0.15)'
                },{
                    offset: 1,
                    color:'rgba(255,26,26,0.00)'
                }])
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 50,
                color: "#ff1a1a",
              }
            },
            data: [],
            animationDelay: function (idx) {
              return idx * 10;
            }
        }, {
            name: 'Receitas',
            type: 'line',
            stack: 'transactions',
            barMaxWidth: 10,
            smooth: false,
            areaStyle:{
              normal:{
                color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                    offset: 0,
                    color:'rgba(55,163,59,0.20)'
                }, {
                    offset: .34,
                    color:'rgba(55,163,59,0.15)'
                },{
                    offset: 1,
                    color:'rgba(55,163,59,0.00)'
                }])
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 50,
                color: "#18b272"
              }
            },
            data: [
              0, 0, 0, 0, 0
            ],
            animationDelay: function (idx) {
              return idx * 10 + 100;
            }
        }],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      },
      optionChartBar: {
        legend: {
          data: ['Despesas', 'Receitas'],
          align: 'left'
        },
        tooltip: {
          trigger: "axis",
          show: true,
          backgroundColor : '#fff',
          axisPointer: {
            snap: true,
            type: "cross",
          },
          formatter: function(params){
            var template = '';
            var balance = '';
            if(params[1]) {
              balance = moneyFormat((params[1].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }
            if(params[0]) {
              balance = moneyFormat((params[0].data));
              template = `${params[0].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }

            if(params[1] && params[0]) {
              balance = moneyFormat((params[1].data) - Math.abs(params[0].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1> <hr style="margin-top:5px;margin-bottom:5px;"> <p style="margin:0px; color: #cccccc !important;">Entradas: <span style="text-align: right; display:block; float: right;"> R$ ${moneyFormat(params[1].data)}</span></p><p style="color: #cccccc !important;">Saídas: <span style="text-align: right; display:block; float: right;">R$ ${moneyFormat(params[0].data)}</span></p>`;
            }
            return template;
          }
        },
        xAxis: {
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 0,
          },
          type: 'category',
          data: [],
          silent: false,
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type : 'value',
          name: '',
          axisLabel: {
            formatter: function (value, index) {
              console.log(index);
              var text = "R$ "+value;
              return text;
            }
          }
        },
        series: [
          {
            name: 'Despesas',
            type: 'bar',
            smooth: false,
            stack: 'all',
            areaStyle:{
              normal:{
                color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                    offset: 0,
                    color:'rgba(255,26,26,0.20)'
                }, {
                    offset: .34,
                    color:'rgba(255,26,26,0.15)'
                },{
                    offset: 1,
                    color:'rgba(255,26,26,0.00)'
                }])
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: "#ff1a1a",
              }
            },
            data: [],
            animationDelay: function (idx) {
              return idx * 10;
            }
        }, {
            name: 'Receitas',
            type: 'bar',
            barMaxWidth: 13,
            smooth: false,
            areaStyle:{
              normal:{
                color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                    offset: 0,
                    color:'rgba(55,163,59,0.20)'
                }, {
                    offset: .34,
                    color:'rgba(55,163,59,0.15)'
                },{
                    offset: 1,
                    color:'rgba(55,163,59,0.00)'
                }])
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: "#18b272"
              }
            },
            data: [
              0, 0, 0, 0, 0
            ],
            animationDelay: function (idx) {
              return idx * 10 + 100;
            }
        }],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      },
      option: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<span class="text-left"><h3 class="color-black no-margin text-left">' +
              params.name +
              " (" +
              params.percent +
              "%)</span></span>";

            return template;
          },
        },
        color: ["#57BE65", "#ff1a1a", "#3EA4F1"],
        series: [
          {
            name: "R$",
            type: "pie",
            radius: ["50%", "70%"],
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 1,
            },
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  provide: {
    [THEME_KEY]: "light",
  },
  setup() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
    console.log('Width', this.windowWidth);
    this.tab1Size = this.windowWidth > 569 ? '33' : '29',
    this.tab2Size = this.windowWidth > 569 ? '64.3' : '59.5',
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    console.log('Mês', this.monthStart);
    pace.start();
    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.optionsDate.startDate = `${moment(new Date()).format('01/MM/YYYY')}`;
    this.optionsDate.endDate = moment(this.from, "YYYY-MM-DD").endOf('month').format('DD/MM/YYYY');
    this.request();

    AppService.getCards({
      from: this.from,
      to: this.to,
    }).then(
      (response) => {
        console.log('Cards');
        console.log(response);
        this.cards = response.cards;
        this.isLoadingCards = false;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );

    AppService.getWallets({}).then(
      (response) => {
        this.isLoadingWallet = false;
        this.wallets = response.wallets;
        console.log(response);
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );
    this.requestInfo(this.tabIncome);
  },
  methods: {
    changeTab(tab, indicator) {
      this.miniTab.tab = tab;
      this.miniTab.indicator = indicator;
      switch (tab) {
        case 0:
          this.tabLine = 'line';
          break;
        case 1:
          this.tabLine = 'bar';
          break;
        case 2:
          this.tabLine = 'pier';
          break;
        default:
          break;
      }
      console.log(this.miniTab.indicator);
    },
    categoryDetail(id, item, total, type) {
      item.from = this.from;
      item.total = total;
      item.tabIncome = type;
      item.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.$root.$emit('show.modal', id, item);
    },
    changeTabPie(tab, indicator) {
      this.pieTab.tab = tab;
      this.pieTab.indicator = indicator;
      switch (tab) {
        case 0:
          this.tabColor = '#ff1a1a';
          this.tabIncome = 'expense';
          break;
        case 1:
          this.tabColor = '#18b272';
          this.tabIncome = 'income';
          break;
        default:
          break;
      }
      this.requestInfo(this.tabIncome);
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
        this.requestInfo(this.tabIncome);
      }
    },
    getPdf() {
      pace.restart();
      AppService.getPdf({
        from: this.from,
        to: this.to,
        filter: this.filters,
        remove_transfer: true,
      }).then(
        (response) => {
          this.$toast.show({
            title: "Sucesso",
            content: "Relatório exportado com sucesso!",
            type: "success",
          });
          console.log(response);
          window.open(response.data.url, '_blank');
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    requestInfo(type) {
      this.optionCat.series[0].data = [];
      AppService.getInfo({
        from: this.from,
        to: this.to,
        no_limit: true,
        type: type ? type : null,
        filter: this.filters,
        show_cards: true,
        invoice_date: true,
        trans_card: true,
      }).then(
        (response) => {
          this.isLoading = false;
          this.report = response;
          console.log(response);
          this.optionCat.color = response.transactions.report.colors_category;
          response.transactions.report.categories.forEach(element => {
            this.optionCat.series[0].data.push(
              {
                value: element.percent,
                name: `${element.category == null ? 'Sem categoria': element.category.parent ? element.category.parent.name : element.category.name} (${element.percent}%)`,
              }
            );
          });
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    request() {
      AppService.boxFlow({
        from: this.from,
        to: this.to,
        get_group: true,
        filter: this.filters,
        check_sub: true,
        invoice_date: true,
        trans_card: true,
        show_cards: true,
      }).then(
        (response) => {
          console.log(response);
          if (response.chart) {
            this.optionChart2.xAxis.data = response.chart.label;
            this.optionChart2.series[0].data = response.chart.expense;
            this.optionChart2.series[1].data = response.chart.income;

            this.optionChartBar.xAxis.data = response.chart.label;
            this.optionChartBar.series[0].data = response.chart.expense;
            this.optionChartBar.series[1].data = response.chart.income;
            this.boxFlow = response;
          }

          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
      this.requestInfo(this.tabIncome);
    },
    navigateDateTo (month) {
      console.log(month);
      this.from = `${moment(new Date()).format('YYYY')}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      AppService.boxFlow({
        from: this.from,
        to: this.to,
        get_group: true,
      }).then(
        (response) => {
          console.log(response);
          this.boxFlow = response;
          this.optionChart2.xAxis.data = this.boxFlow.chart.label;
          this.optionChart2.series[0].data = this.boxFlow.chart.expense;
          this.optionChart2.series[1].data = this.boxFlow.chart.income;

          this.optionChartBar.xAxis.data = this.boxFlow.chart.label;
          this.optionChartBar.series[0].data = this.boxFlow.chart.expense;
          this.optionChartBar.series[1].data = this.boxFlow.chart.income;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 300px;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.figure-stats {
  border-right: none;
  border-left: none;
}
.mini-tab-b-top {
  font-size: 15px;
}
.total-balance p{
  margin-top: 30px;
}
.total-balance h2{
  margin-bottom: 0px;
  margin-top: 0;
  font-size: 40px;
  font-weight: 100;
}
</style>
